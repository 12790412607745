export type InterfacePreferences = {
  columns?: {
    [pathname: string]: string[] | undefined;
  };
  pageSize?: number;
};

export function getPreferencedColumns(
  preferences: JSON | undefined,
  pathname: string
): string[] | undefined {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return preferencesObject?.columns?.[pathname];
}

export function setPreferencedColumns(
  preferences: JSON | undefined,
  pathname: string,
  columns: string[] | undefined
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    columns: {
      ...preferencesObject.columns,
      [pathname]: columns,
    },
  };
}

export function getPreferencedPageSize(
  preferences: JSON | undefined
): number | undefined {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return preferencesObject?.pageSize;
}

export function setPreferencedPageSize(
  preferences: JSON | undefined,
  pageSize: number | undefined
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    pageSize,
  };
}
