import { useStyletron } from "baseui";
import { useAuth } from "contexts/auth-context";
import React from "react";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { ACTIVITY_LOG_ACTIVITIES } from "../../constants";
import Select, { SelectProps } from "./select";

export default function ActivitiesSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const hasPermission = checkPermission(PERMISSIONS.activityLog.read);

  return (
    <Select
      options={ACTIVITY_LOG_ACTIVITIES}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: " 5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania akcji
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      {...rest}
    />
  );
}
