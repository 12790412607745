import { useStyletron } from "baseui";
import { InputProps } from "baseui/input";
import { Tooltip } from "components/tooltip";
import React, { useEffect, useState } from "react";
import { UseControllerProps, useFormContext } from "react-hook-form";
import { Refresh } from "tabler-icons-react";
import { slugifyText } from "utils/form/slugify";

import { ControlledInput } from ".";

type SlugInputProps = InputProps & { formType: "update" | "create" };

export default function SlugInput({
  name,
  formType,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SlugInputProps): React.ReactElement {
  const [css] = useStyletron();
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);

  const {
    clearErrors,
    control,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useFormContext();
  const watchName = watch("name");
  const watchSlug = watch("slug");

  useEffect(() => {
    if (!isSlugChangedByUser && formType === "create")
      setValue("slug", slugifyText(watchName));
  }, [watchName]);

  useEffect(() => {
    if (watchSlug !== slugifyText(watchName) && !!dirtyFields.slug)
      setIsSlugChangedByUser(true);
  }, [watchSlug]);

  useEffect(() => {
    if (!isSlugChangedByUser) clearErrors("slug");
  }, [isSlugChangedByUser]);

  const handleDefaultSlug = () => {
    setIsSlugChangedByUser(false);
    setValue("slug", slugifyText(watchName));
  };

  return (
    <ControlledInput
      name={name}
      control={control}
      endEnhancer={
        <Tooltip
          {...(isSlugChangedByUser && {
            content: "Wygeneruj na podstawie nazwy",
          })}
        >
          <span
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Refresh
              size={16}
              {...(!isSlugChangedByUser && {
                color: "gray",
              })}
              className={css({
                cursor: isSlugChangedByUser ? "pointer" : "not-allowed",
              })}
              onClick={handleDefaultSlug}
              data-testid="generateSlug"
            />
          </span>
        </Tooltip>
      }
      {...(!isSlugChangedByUser && {
        $style: {
          color: "gray",
        },
      })}
      {...rest}
    />
  );
}
