import Slugify from "slugify";

export const slugifyText = (text?: string): string =>
  text
    ? Slugify(text, {
        remove: /[*+~=§.,/(){}^_`'"!?:;@-]/g,
        strict: true,
        lower: true,
        locale: "pl",
      }).slice(0, 100)
    : "";
