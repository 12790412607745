import { BasicFilter, FilterType } from "./../../filters.d";

export const SEO_TAGSETS_FILTERS: BasicFilter[] = [
  {
    id: "subjectType",
    label: "Obiekt",
    type: FilterType.SeoTagsetsSubjectType,
  },
  {
    id: "metaTitle",
    label: "Tytuł meta",
    type: FilterType.Text,
  },
  {
    id: "metaDescription",
    label: "Opis meta",
    type: FilterType.Text,
  },
  {
    id: "ogTitle",
    label: "Tytuł OG",
    type: FilterType.Text,
  },
  {
    id: "ogDescription",
    label: "Opis OG",
    type: FilterType.Text,
  },
  {
    id: "metaKeywords",
    label: "Słowa kluczowe meta",
    type: FilterType.Text,
  },
  {
    id: "robots",
    label: "Robots",
    type: FilterType.Text,
  },
  {
    id: "canonical",
    label: "Link canonical",
    type: FilterType.Text,
  },
];
