import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
  mutation($changePasswordInput: ChangePasswordInput!) {
    changePassword(changePasswordInput: $changePasswordInput)
  }
`;

export const ACCOUNT_UPDATE = gql`
  mutation($accountUpdateInput: AccountUpdateInput!) {
    accountUpdate(accountUpdateInput: $accountUpdateInput) {
      id
    }
  }
`;
