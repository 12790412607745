import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      accessToken
      user {
        id
        firstName
        lastName
        email
        interfacePreferences
        permissions {
          id
          systemName
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput)
  }
`;

export const RESET_PASSWORD = gql`
  mutation($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput)
  }
`;

export const SET_INITIAL_PASSWORD = gql`
  mutation($setInitialPasswordInput: SetInitialPasswordInput!) {
    setInitialPassword(setInitialPasswordInput: $setInitialPasswordInput)
  }
`;
