import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPreferencedPageSize } from "utils/interface/preferences";

import { PAGE_SIZE } from "../constants";
import { useAuth } from "./auth-context";

type PagingContextProps = {
  pageSize: number;
  currentPage: number;
  totalCount: number;
  changePage: (number: number) => void;
  setTotalCount: (number: number) => void;
  setPageSize: (number: number) => void;
  reset: () => void;
};

export const PagingContext = createContext<PagingContextProps>(
  {} as PagingContextProps
);

export function PagingProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { interfacePreferences } = useAuth();

  const preferencedPageSize = getPreferencedPageSize(interfacePreferences);

  const [pageSize, setPageSize] = useState<number>(
    preferencedPageSize || PAGE_SIZE
  );

  const [totalCount, setTotalCount] = useState<number>(0);

  const history = useHistory();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);
  const currentPage = useMemo(() => parseInt(params.get("page") as string), [
    params,
  ]);

  function changePage(number: number) {
    history.push({
      search: new URLSearchParams({
        ...(params && Object.fromEntries(params)),
        page: number.toString(),
      }).toString(),
    });

    document?.querySelector("main > section")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }

  function reset() {
    changePage(1);
  }

  useEffect(() => {
    pageSize !== preferencedPageSize &&
      preferencedPageSize &&
      setPageSize(preferencedPageSize);
  }, [preferencedPageSize]);

  return (
    <PagingContext.Provider
      value={{
        pageSize,
        currentPage: !isNaN(currentPage) ? currentPage : 1,
        totalCount,
        changePage,
        setTotalCount,
        setPageSize,
        reset,
      }}
    >
      {children}
    </PagingContext.Provider>
  );
}

export const usePaging = (): PagingContextProps => useContext(PagingContext);
