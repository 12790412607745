import { ApolloError, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { HeadingLarge } from "baseui/typography";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { FormControl } from "components/form-control";
import { Grid } from "components/grid";
import { ControlledInput } from "components/input";
import { LoadingBar } from "components/loading-bar";
import { useLoading } from "contexts/loading-context";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";

import { INPUT_VALIDATION_ERROR } from "../../../constants";
import { SET_INITIAL_PASSWORD } from "../auth.gql";

type InvitationFormInputs = {
  password: string;
  repeatedPassword: string;
};

enum InvitationField {
  Password = "password",
  RepeatedPassword = "repeatedPassword",
}

export default function Invitation(): React.ReactElement {
  const [css] = useStyletron();
  const { isLoading, setIsLoading } = useLoading();
  const { token }: { token: string } = useParams();
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<InvitationFormInputs>({
    defaultValues: {
      password: "",
      repeatedPassword: "",
    },
  });
  const watchFields = watch();

  const [setInitialPassword, { error }] = useMutation(SET_INITIAL_PASSWORD);

  const onSubmit = async (values: InvitationFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await setInitialPassword({
        variables: {
          setInitialPasswordInput: {
            password: values.password,
            token,
          },
        },
      });

      enqueueSnackbar({
        message: "Hasło zostało ustawione",
        variant: "success",
      });

      history.push("/login");
    } catch (error: unknown) {
      (error as ApolloError)?.graphQLErrors?.map(({ message }) => {
        return enqueueSnackbar({
          message,
          variant: "error",
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <LoadingBar />
      <Grid $style={{ height: "calc(100vh - 5px)" }}>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingLeft: "50px",
              paddingRight: "50px",
            })}
          >
            <div
              className={css({
                width: "100%",
                maxWidth: "400px",
              })}
            >
              <HeadingLarge
                marginTop="scale0"
                marginBottom="scale1000"
                $style={{ textAlign: "center" }}
              >
                Ustawienie hasła
              </HeadingLarge>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  label="Hasło"
                  required
                  error={
                    (errors.password && errors.password.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        INPUT_VALIDATION_ERROR &&
                      !!error.graphQLErrors[0]?.extensions?.validationErrors?.find(
                        (vE: InputValidationError) =>
                          vE?.property === InvitationField.Password
                      )?.errors.length)
                  }
                  disabled={isLoading}
                >
                  <ControlledInput
                    control={control}
                    type="password"
                    name={InvitationField.Password}
                    autoComplete="new-password"
                    rules={{
                      required: FORM_VALIDATION_MESSAGES.required,
                      validate: (value) =>
                        value === watchFields?.repeatedPassword ||
                        FORM_VALIDATION_MESSAGES.differentPasswords,
                    }}
                  />
                </FormControl>
                <FormControl
                  label="Powtórz hasło"
                  required
                  error={
                    (errors.password && errors.password.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        INPUT_VALIDATION_ERROR &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === InvitationField.Password
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                          </div>
                        ))[0])
                  }
                  disabled={isLoading}
                >
                  <ControlledInput
                    control={control}
                    type="password"
                    name={InvitationField.RepeatedPassword}
                    autoComplete="new-password"
                    rules={{
                      required: FORM_VALIDATION_MESSAGES.required,
                      validate: (value) =>
                        value === watchFields?.password ||
                        FORM_VALIDATION_MESSAGES.differentPasswords,
                    }}
                  />
                </FormControl>
                <Button
                  type="submit"
                  size={SIZE.large}
                  kind={KIND.primary}
                  isSelected={isLoading}
                  isLoading={isLoading}
                  $style={{ width: "100%", marginTop: "15px" }}
                >
                  Zapisz
                </Button>
              </form>
            </div>
          </div>
        </Cell>
      </Grid>
    </main>
  );
}
