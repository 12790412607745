import { useStyletron } from "baseui";
import {
  Input as BaseInput,
  InputProps,
  MaskedInput as BaseMaskedInput,
  MaskedInputProps,
} from "baseui/input";
import React, { ForwardedRef, forwardRef, HTMLProps } from "react";
import { ChangeEvent } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";
import { X } from "tabler-icons-react";

type Props = {
  $style?: StyleObject;
  masked?: boolean;
  maskChar?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: ForwardedRef<any>;
} & InputProps &
  MaskedInputProps;

const Input = ({
  $style,
  maskChar,
  masked,
  forwardedRef,
  endEnhancer,
  size,
  maxLength,
  ...rest
}: Props): React.ReactElement => {
  const [css, theme] = useStyletron();
  const Component = masked ? BaseMaskedInput : BaseInput;

  return (
    <Component
      inputRef={forwardedRef}
      overrides={{
        Root: {
          style: () => ({
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            paddingRight: "5px",
            appearance: "textfield",
            "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            ...$style,
          }),
        },
        InputContainer: {
          style: {},
        },
        Input: {
          style: {
            paddingLeft: "12px",
            backgroundColor: "transparent",
            ":-webkit-autofill": {
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
            },
            paddingRight: 0,
            ...$style,
          },
          props: {
            "data-masked": !!masked,
          },
        },
        EndEnhancer: {
          style: {
            fontSize: "12px",
            marginRight: 0,
          },
        },
        StartEnhancer: {
          style: {
            fontSize: "12px",
            padding: 0,
          },
        },
        ClearIcon: {
          props: {
            overrides: {
              Svg: (props: HTMLProps<HTMLElement>) => (
                <span
                  className={css({
                    marginRight: "5px",
                    cursor: "pointer",
                    display: "inline",
                    lineHeight: 0,
                  })}
                  {...props}
                >
                  <X color={theme.colors.borderFocus} size={18} />
                </span>
              ),
            },
          },
        },
      }}
      {...rest}
      size={size || "compact"}
      maskChar={maskChar}
      endEnhancer={endEnhancer}
      maxLength={maxLength}
    />
  );
};

export default Input;

export function ControlledInput({
  control,
  name,
  rules,
  type,
  maxLength,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            type === "number"
              ? onChange(
                  !!parseFloat(e.target.value) &&
                    Math.abs(parseFloat(e.target.value)) >= 0
                    ? Math.abs(parseFloat(e.target.value))
                    : null
                )
              : onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          type={type}
          name={name}
          id={name}
          maxLength={maxLength}
          {...rest}
        />
      )}
    />
  );
}

// eslint-disable-next-line react/display-name
export const ForwardedInput = forwardRef((
  props: Props & Omit<HTMLProps<"input">, keyof Props>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: ForwardedRef<any>
) => <Input forwardedRef={ref} {...props} />);
