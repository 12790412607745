import { gql } from "@apollo/client";

export const ROLES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: RoleFilter
    $sorting: [RoleSort!]
  ) {
    roles(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        description
        isSystem
        permissions {
          id
        }
      }
    }
  }
`;

export const ROLES_SHOW = gql`
  query RoleShow($id: Int!) {
    role(id: $id) {
      id
      name
      description
      isSystem
      permissions {
        id
      }
    }
  }
`;

export const ROLES_CREATE = gql`
  mutation RoleCreate($roleCreateInput: RoleCreateInput!) {
    roleCreate(roleCreateInput: $roleCreateInput) {
      id
    }
  }
`;

export const ROLES_UPDATE = gql`
  mutation RoleUpdate($roleUpdateInput: RoleUpdateInput!) {
    roleUpdate(roleUpdateInput: $roleUpdateInput) {
      id
    }
  }
`;

export const ROLES_DELETE = gql`
  mutation RoleDelete($roleDeleteInput: RoleDeleteInput!) {
    roleDelete(roleDeleteInput: $roleDeleteInput) {
      id
    }
  }
`;

export const PERMISSION_GROUPS = gql`
  query {
    permissionGroups {
      id
      name
      description
      permissions {
        id
        name
        systemName
        description
        permissionGroupId
      }
    }
  }
`;

export const PERMISSIONS_INDEX = gql`
  query PermissionsIndex {
    permissions {
      id
      name
      permissionGroupId
    }
  }
`;
