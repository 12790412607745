import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { useLoading } from "contexts/loading-context";
import React from "react";
import { StyleObject } from "styletron-react";
import { ArrowDown, ArrowsVertical, ArrowUp } from "tabler-icons-react";

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  sortDirection?: SortDirection | null;
  $style?: StyleObject;
};

export default function SortingTableHeader({
  children,
  onClick,
  sortDirection,
  $style,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const { isFetching } = useLoading();

  return (
    <Block
      display="flex"
      alignItems="center"
      className={css({
        cursor: "pointer",
        ...$style,
      })}
      {...(!isFetching && {
        onClick: () => {
          onClick();

          document?.querySelector("main > section")?.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
          });
        },
      })}
    >
      {sortDirection ? (
        sortDirection === SortDirection.ASC ? (
          <ArrowUp
            size={14}
            className={css({ marginRight: "5px", flexShrink: 0 })}
          />
        ) : (
          <ArrowDown
            size={14}
            className={css({ marginRight: "5px", flexShrink: 0 })}
          />
        )
      ) : (
        <ArrowsVertical
          size={14}
          color={theme.colors.mono600}
          className={css({ marginRight: "5px", flexShrink: 0 })}
        />
      )}
      {children}
    </Block>
  );
}
