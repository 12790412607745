import { createTheme } from "baseui";

const primitives = {
  primaryFontFamily: "'Inter', sans-serif",
};

const overrides = {
  colors: {
    primary: "#254278",
    backgroundTertiary: "#f0f0f0",
    contentAccent: "#254278",
    buttonPrimaryFill: "#254278",
    buttonPrimaryHover: "#032056",
    buttonPrimaryActive: "#032056",
    buttonPrimarySpinnerForeground: "white",
    buttonPrimarySpinnerBackground: "rgba(255,255,255,0.6)",
    backgroundInverseSecondary: "#254278",
    tagPrimarySolidBackground: "#254278",
    inputFill: "#f9f9f9",
    inputBorder: "#eeeeee",
    progressbarTrackFill: "#f9f9f9",
  },
  typography: {
    LabelXSmall: {
      fontSize: "11px",
      lineHeight: "15px",
    },
    LabelSmall: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    LabelMedium: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    HeadingSmall: {
      fontSize: "20px",
      lineHeight: "28px",
    },
    DisplayXSmall: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
};

export default createTheme(primitives, overrides);
