import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import FormattedValue, {
  DataType,
} from "components/formatted-value/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Table } from "components/table";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Cell as TableCell, Row } from "react-table";
import {
  FileExport,
  Lock,
  LockOpen,
  Pencil,
  Plus,
  Trash,
} from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Dictionary } from "../dictionaries";
import { DICTIONARIES_SHOW, DICTIONARY_EXPORT } from "../dictionaries.gql";
import ValueCreate from "../ValueCreate";
import ValueDelete from "../ValueDelete";
import ValueUpdate from "../ValueUpdate";

export default function DictionariesShow(): React.ReactElement {
  const [isCreateValueModalOpen, setIsCreateValueModalOpen] = useState<boolean>(
    false
  );
  const [isDeleteValueModalOpen, setIsDeleteValueModalOpen] = useState<boolean>(
    false
  );
  const [isUpdateValueModalOpen, setIsUpdateValueModalOpen] = useState<boolean>(
    false
  );
  const [valueId, setValueId] = useState<number>();
  const [valueName, setValueName] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const { id }: { id: string } = useParams();
  const { checkPermission } = useAuth();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const { data, error, refetch } = useQuery(DICTIONARIES_SHOW, {
    variables: { id: parseInt(id), showHidden: true },
  });
  const dictionary = data?.dictionary;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (dictionary?.values) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data && setIsFetching(false), [data]);

  const [exportDictionary] = useMutation(DICTIONARY_EXPORT, {
    variables: {
      id: id ? parseInt(id) : null,
    },
  });

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await exportDictionary();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "success",
      });

      window.open(response?.data?.dictionaryExportToXlsx, "_self");
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <div>
            {row.original.isSystem ? (
              <Lock
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.negative,
                })}
              />
            ) : (
              <LockOpen
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.positive,
                })}
              />
            )}
            {row.original.name}
          </div>
        ),
      },
      {
        Header: "Wartość",
        accessor: "value",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: "Widoczność",
        accessor: "isHidden",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.VisibilityBoolean}>
            {!cell.value}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {!row.original.isSystem &&
              checkPermission(PERMISSIONS.dictionary.valueUpdate) && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() => {
                    setValueId(row.original.id);
                    setValueName(row.original.name);
                    setIsUpdateValueModalOpen(!isUpdateValueModalOpen);
                  }}
                  startEnhancer={<Pencil size={14} />}
                  title="Edytuj"
                />
              )}

            {!row.original.isSystem &&
              checkPermission(PERMISSIONS.dictionary.valueDelete) && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() => {
                    setValueId(row.original.id);
                    setValueName(row.original.name);
                    setIsDeleteValueModalOpen(!isDeleteValueModalOpen);
                  }}
                  $style={{ marginLeft: "5px" }}
                  startEnhancer={<Trash size={14} />}
                  title="Usuń"
                />
              )}
          </div>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.dictionary.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={dictionary?.name}
        recordsNum={dictionary?.values?.length}
        labels={["Słowniki", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Dodaj wartość",
            kind: KIND.primary,
            onClick: () => setIsCreateValueModalOpen(!isCreateValueModalOpen),
            startEnhancer: <Plus size={18} />,
            permission:
              !dictionary?.isSystem &&
              checkPermission(PERMISSIONS.dictionary.valueCreate),
          },
        ]}
        actions={[
          {
            label: "Eksport do pliku XLSX",
            icon: FileExport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.dictionary.read),
            onClick: onSubmit,
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<Dictionary>
                  columns={columns}
                  data={dictionary?.values}
                  compact
                  stickLastColumn
                />
              )
            )}
          </Cell>
        </Grid>
      </Content>
      <ValueCreate
        close={() => setIsCreateValueModalOpen(false)}
        dictionaryId={parseInt(id)}
        isOpen={isCreateValueModalOpen}
        onCompleted={refetch}
      />
      <ValueDelete
        close={() => setIsDeleteValueModalOpen(false)}
        id={valueId}
        isOpen={isDeleteValueModalOpen}
        onCompleted={refetch}
        valueName={valueName}
      />
      <ValueUpdate
        close={() => setIsUpdateValueModalOpen(false)}
        dictionaryId={parseInt(id)}
        id={valueId}
        isOpen={isUpdateValueModalOpen}
        onCompleted={refetch}
      />
    </article>
  );
}
