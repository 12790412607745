import React, { createContext, useContext, useState } from "react";

type LoadingContextProps = {
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  isPartialFetching: boolean;
  setIsPartialFetching: (isPartialFetching: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const LoadingContext = createContext<LoadingContextProps>(
  {} as LoadingContextProps
);

export function LoadingProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isFetching, setIsFetching_] = useState(false);
  const [isPartialFetching, setIsPartialFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function setIsFetching(isFetching: boolean) {
    setIsFetching_(isFetching);
    setIsPartialFetching(isFetching);
  }

  return (
    <LoadingContext.Provider
      value={{
        isFetching,
        setIsFetching,
        isPartialFetching,
        setIsPartialFetching,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = (): LoadingContextProps => useContext(LoadingContext);
