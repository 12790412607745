import { Field, FieldsGroup } from "../fields";

export const scrollOnError = (
  errors: Record<string, unknown>,
  documentFields: FieldsGroup[],
  formType: "create" | "update"
): void => {
  const errorsArray = errors ? Object.keys(errors) : [];
  const fieldsArray = documentFields
    ?.map((group: { fields: Field[] }) => [
      group.fields
        .filter((field: Field) => field[formType]?.visible)
        .map((field: { id: string }) => field.id),
    ])
    .flat(2);

  const firstFieldWithError = fieldsArray.find((field: string) =>
    errorsArray.includes(field)
  );
  if (firstFieldWithError)
    return document
      .getElementById(firstFieldWithError)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
};
