import { useStyletron } from "baseui";
import { LabelSmall } from "baseui/typography";
import { Select } from "components/select";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import React from "react";
import { setPreferencedPageSize } from "utils/interface/preferences";

export default function PageSizeControl(): React.ReactElement {
  const [css] = useStyletron();
  const { isFetching } = useLoading();
  const { interfacePreferences, updateUser } = useAuth();
  const { pageSize, setPageSize, changePage } = usePaging();

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
      })}
    >
      <LabelSmall>Liczba rekordów na stronę:</LabelSmall>

      <Select
        size="mini"
        searchable={false}
        clearable={false}
        disabled={isFetching}
        value={[{ id: pageSize }]}
        $style={{ width: "75px", marginLeft: "12px" }}
        options={[
          { id: 25, label: "25" },
          { id: 50, label: "50" },
          { id: 100, label: "100" },
        ]}
        onChange={(params) => {
          if (params.option) {
            setPageSize(params.option.id as number);

            updateUser({
              interfacePreferences: setPreferencedPageSize(
                interfacePreferences,
                params.option.id as number
              ) as JSON,
            });

            changePage(1);
          }
        }}
      />
    </div>
  );
}
