import { BasicFilter, FilterType } from "./../../filters.d";
import { DictionariesField } from "./dictionaries.form";

export const DICTIONARIES_FILTERS: BasicFilter[] = [
  {
    id: DictionariesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: DictionariesField.SystemName,
    label: "Nazwa systemowa",
    type: FilterType.Text,
  },
];
