import { gql, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { DictionaryValue } from "containers/Dictionaries/dictionaries";
import { useAuth } from "contexts/auth-context";
import React, { useEffect } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { SelectProps } from "./select";

type DictionaryValuesSelectProps = {
  error?: boolean;
  systemName?: string;
  disabled?: boolean;
};

export default function DictionaryValuesSelect({
  error,
  systemName,
  disabled,
  ...rest
}: DictionaryValuesSelectProps & SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const { data, refetch } = useQuery(
    gql`
      query($systemName: String!) {
        dictionary(systemName: $systemName) {
          id
          values {
            id
            value
            name
          }
        }
      }
    `,
    {
      variables: {
        systemName,
      },
    }
  );

  useEffect(() => {
    if (data) refetch();
  }, []);

  const hasPermission = checkPermission(PERMISSIONS.category.read);

  return (
    <Select
      disabled={disabled}
      error={error}
      options={data?.dictionary?.values?.map(
        ({ value, name }: DictionaryValue) => ({
          id: value,
          label: name,
        })
      )}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: " 5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania przedmiotów aktywności
          </div>
        )
      }
      {...rest}
    />
  );
}

export function ControlledDictionaryValuesSelect({
  control,
  disabled,
  name,
  rules,
  systemName,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & DictionaryValuesSelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <DictionaryValuesSelect
          id={name}
          systemName={systemName}
          disabled={disabled}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
