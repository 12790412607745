import { ApolloError, useMutation } from "@apollo/client";
import { ConfirmDialog } from "components/confirm-dialog";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React from "react";

import { DICTIONARY_VALUE_DELETE } from "../dictionaries.gql";

type ValueDeleteProps = {
  close: () => void;
  id: number | undefined;
  isOpen: boolean;
  onCompleted: () => void;
  valueName?: string;
};

export default function ValueDelete({
  close,
  id,
  isOpen,
  onCompleted,
  valueName,
}: ValueDeleteProps): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();

  const [deleteDictionaryValue] = useMutation(DICTIONARY_VALUE_DELETE);

  const deleteValue = async ({
    id,
  }: {
    id: number | undefined;
  }): Promise<void> => {
    setIsLoading(true);

    try {
      await deleteDictionaryValue({
        variables: {
          dictionaryValueDeleteInput: {
            id,
          },
        },
      });
      enqueueSnackbar({
        message: "Wartość została usunięta",
        variant: "success",
      });
      onCompleted();
      close();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      label={`Usunięcie wartości ${valueName}`}
      close={close}
      confirm={() => deleteValue({ id })}
    />
  );
}
