import { FieldsGroup, FieldType } from "../../fields.d";

export type RolesFormInputs = {
  name: string;
  description?: string;
  permissionIds?: number[];
};

export enum RolesField {
  Name = "name",
  Description = "description",
  IsSystem = "isSystem",
  PermissionIds = "permissionIds",
}

export const ROLES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: RolesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        autocomplete: "none",
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: RolesField.Description,
        label: "Opis",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "permissions",
    label: "",
    fields: [
      {
        id: RolesField.PermissionIds,
        label: "Uprawnienia",
        span: 12,
        type: FieldType.PermissionsTable,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
];
