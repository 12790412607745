import { useStyletron } from "baseui";
import { DataType, FormattedValue } from "components/formatted-value";
import { Tooltip } from "components/tooltip";
import React, { ReactElement } from "react";

type ListTooltipProps = {
  items?: { id: number; name: string }[];
  dataType: DataType;
};

export default function ListTooltip({
  items,
  dataType,
}: ListTooltipProps): ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Tooltip
      content={
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            padding: "8px",
          })}
        >
          {items?.map(({ id, name }: { id: number; name: string }) => (
            <FormattedValue
              key={`item-${dataType}-${id}`}
              dataType={dataType}
              data={id}
            >
              {name}
            </FormattedValue>
          ))}
        </div>
      }
      placement="left"
    >
      <span
        className={css({
          backgroundColor: theme.colors.primary,
          color: "white",
          fontWeight: 600,
          padding: "0 5px",
          borderRadius: "5px",
          cursor: "pointer",
        })}
      >
        {items?.length && `+${items?.length - 1}`}
      </span>
    </Tooltip>
  );
}
