import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { Button } from "components/button";
import { Modal } from "components/modal";
import { useLoading } from "contexts/loading-context";
import React from "react";

type ConfirmDialogProps = {
  isOpen: boolean;
  label?: string;
  close: () => void;
  confirm: () => void;
  needComment?: boolean;
};

export default function ConfirmDialog({
  isOpen,
  label,
  close,
  confirm,
}: ConfirmDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading } = useLoading();

  return (
    <Modal
      onClose={close}
      closeable={!isLoading}
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Potwierdź akcję</ModalHeader>

      <ModalBody>
        <p>Ta akcja wymaga dodatkowego potwierdzenia.</p>
        <p>
          <strong className={css({ whiteSpace: "pre-wrap" })}>{label}</strong>
        </p>
      </ModalBody>

      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
          disabled={isLoading}
        >
          Anuluj
        </Button>

        <Button disabled={isLoading} isLoading={isLoading} onClick={confirm}>
          Potwierdź
        </Button>
      </ModalFooter>
    </Modal>
  );
}
