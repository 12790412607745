import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { SIZE } from "baseui/button";
import { LabelSmall } from "baseui/typography";
import { Action } from "components/actions-list";
import { ForwardedButton } from "components/button";
import { Popover } from "components/popover";
import React from "react";
import { DotsVertical } from "tabler-icons-react";

type ActionsListProps = {
  actions: Action[];
  sticked?: boolean;
};

export default function HeaderActionsList({
  actions,
  sticked,
}: ActionsListProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Popover
      renderAll
      content={({ close }) => (
        <Block padding="scale300">
          <LabelSmall
            $style={{
              fontWeight: 600,
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.colors.inputBorder,
              paddingBottom: theme.sizing.scale300,
              marginBottom: "8px",
            }}
          >
            Akcje
          </LabelSmall>

          {actions.map(
            (action: Action, index: number) =>
              (action.permission || action.permission === undefined) && (
                <div
                  onClick={() => close()}
                  className={css({
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dotted",
                    borderBottomColor: theme.colors.inputBorder,
                    ":last-of-type": {
                      borderBottomWidth: 0,
                    },
                  })}
                >
                  <div
                    key={`action-${index + 1}`}
                    {...(action.onClick && {
                      onClick: action.onClick,
                    })}
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "6px",
                      marginRight: "8px",
                      padding: "8px 4px",
                      color: action.color || "#000",
                      ":hover": {
                        backgroundColor: "#f9f9f9",
                        cursor: "pointer",
                      },
                    })}
                  >
                    <div
                      className={css({
                        marginRight: "10px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      })}
                    >
                      <action.icon size={16} role="icon" />
                    </div>
                    <span className={css({ fontSize: "14px" })}>
                      {action.label}
                    </span>
                  </div>
                </div>
              )
          )}
        </Block>
      )}
      placement="bottomRight"
      $style={{ zIndex: 150 }}
    >
      <ForwardedButton
        data-ui="actions"
        kind="secondary"
        size={sticked ? SIZE.compact : SIZE.default}
        type="button"
        $style={{
          position: "relative",
          paddingBottom: 0,
          paddingTop: 0,
          paddingLeft: "8px",
          paddingRight: "8px",
          height: sticked ? "38px" : "42px",
        }}
      >
        <DotsVertical size={sticked ? 16 : 18} />
      </ForwardedButton>
    </Popover>
  );
}
