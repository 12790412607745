import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

export default function NoPermissionsRedirect(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar({
      message: "Brak uprawnień w systemie",
      variant: "error",
    });
  }, []);
  return <Redirect to="/" />;
}
