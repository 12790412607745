import { ApolloError, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { Content } from "components/content";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Pencil } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { SeoTagset } from "../seo-tagsets";
import { SEO_TAGSETS_FIELDS } from "../seo-tagsets.form";
import { SEO_TAGSETS_SHOW } from "../seo-tagsets.gql";

export default function SeoTagsetsShow(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();
  const { setIsFetching } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();

  const { refetch, data, error } = useQuery(SEO_TAGSETS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const seoTagset: SeoTagset = data?.seoTagset;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.seoTagset) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.seoTagset && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.seoTagset.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={`Tagi SEO ${seoTagset?.id}`}
        labels={["Tagi SEO", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.seoTagset.update),
            onClick: () => history.push(`/seo-tagsets/${id}/update`),
          },
        ]}
      />
      <Content>
        <ObjectViewRenderer<SeoTagset>
          fields={SEO_TAGSETS_FIELDS}
          data={seoTagset}
        />
      </Content>
    </article>
  );
}
