import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";

import { DIFFICULTY_LEVELS } from "../../constants";
import Select, { SelectProps } from "./select";

export function ControlledDifficultySelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={DIFFICULTY_LEVELS.map(
            ({ id, label }: { id: string; label: string }) => ({
              id,
              label,
            })
          )}
          getValueLabel={({ option }) => `${option?.label}`}
          getOptionLabel={({ option }) => `${option?.label}`}
          placeholder="Wybierz"
          disabled={disabled}
          onChange={(params) => params && onChange(params.value)}
          value={value}
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  );
}
