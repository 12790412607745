import { FieldsGroup, FieldType } from "../../fields.d";

export enum DictionariesField {
  Name = "name",
  SystemName = "systemName",
}

export enum DictionaryValuesField {
  Name = "name",
  Value = "value",
  IsHidden = "isHidden",
}

export type DictionaryValuesFormInputs = {
  name: string;
  value: string;
  isHidden: boolean;
};

export const DICTIONARY_VALUES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "",
    fields: [
      {
        id: DictionaryValuesField.Name,
        label: "Nazwa",
        type: FieldType.Input,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: DictionaryValuesField.Value,
        label: "Wartość",
        type: FieldType.Input,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: DictionaryValuesField.IsHidden,
        label: "",
        type: FieldType.Checkbox,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
];
