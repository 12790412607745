import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { CheckboxProps } from "baseui/checkbox";
import { Button } from "components/button";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Check, X } from "tabler-icons-react";

type Props = {
  onChange: (value: boolean) => void;
} & Omit<CheckboxProps, "onChange">;

const Switch = ({
  checked,
  onChange,
  disabled,
  name,
}: Props): React.ReactElement => {
  const [, theme] = useStyletron();

  return (
    <Block
      display="flex"
      backgroundColor={"inputBorder"}
      data-test-id={name}
      overrides={{
        Block: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            height: "36px",
            padding: "2px",
            gap: "2px",
            ...(disabled && {
              pointerEvents: "none",
              opacity: 0.8,
            }),
          },
        },
      }}
      onClick={() => onChange(!checked)}
    >
      <Button
        type="button"
        startEnhancer={
          <X
            color={checked === false ? "white" : theme.colors.negative}
            size={16}
          />
        }
        size="mini"
        kind={checked === false ? "primary" : "secondary"}
        $style={{
          flexGrow: 1,
          flexShrink: 0,
          ...(checked === false && {
            backgroundColor: theme.colors.negative,
            ":hover": {
              backgroundColor: theme.colors.negative500,
            },
            ":focus": {
              backgroundColor: theme.colors.negative500,
            },
          }),
        }}
      >
        Nie
      </Button>
      <Button
        type="button"
        startEnhancer={
          <Check
            color={checked === true ? "white" : theme.colors.positive}
            size={16}
          />
        }
        size="mini"
        kind={checked === true ? "primary" : "secondary"}
        $style={{
          flexGrow: 1,
          flexShrink: 0,
          ...(checked && {
            backgroundColor: theme.colors.positive,
            ":hover": {
              backgroundColor: theme.colors.positive500,
            },
            ":focus": {
              backgroundColor: theme.colors.positive500,
            },
          }),
        }}
      >
        Tak
      </Button>
    </Block>
  );
};

export default Switch;

export function ControlledSwitch({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Omit<Props, "onChange">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Switch
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
          name={name}
          {...rest}
        />
      )}
    />
  );
}
