import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import FormattedValue, {
  DataType,
} from "components/formatted-value/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Lock, LockOpen } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";

import { FiltersState } from "../../../filters";
import { Dictionary } from "../dictionaries";
import { DICTIONARIES_FILTERS } from "../dictionaries.filters";
import { DictionariesField } from "../dictionaries.form";
import { DICTIONARIES_INDEX } from "../dictionaries.gql";

export default function DictionariesIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<DictionariesField | null>(
    DictionariesField.Name
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const [filters, setFilters] = useState<FiltersState>();
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { checkPermission } = useAuth();

  const handleSorting = (column: DictionariesField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(DICTIONARIES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
    },
  });
  const dictionaries: { totalCount: number; nodes: Dictionary[] } =
    data?.dictionaries;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (dictionaries) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
    if (dictionaries?.totalCount >= 0)
      setTotalCount(data?.dictionaries?.totalCount);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(DictionariesField.Name)}
            sortDirection={
              sortBy === DictionariesField.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: DictionariesField.Name,
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/dictionaries/${row.original.id}`);
            }}
            href={`/dictionaries/${row.original.id}`}
          >
            {row.original.isSystem ? (
              <Lock
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.negative,
                })}
              />
            ) : (
              <LockOpen
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.positive,
                })}
              />
            )}
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(DictionariesField.SystemName)}
            sortDirection={
              sortBy === DictionariesField.SystemName ? sortDirection : null
            }
          >
            Nazwa systemowa
          </SortingTableHeader>
        ),
        id: DictionariesField.SystemName,
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row?.original?.systemName}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() => history.push(`/dictionaries/${row.original.id}/`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.dictionary.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Słowniki"
        recordsNum={dictionaries?.totalCount}
        labels={["Lista"]}
      />
      <Filters
        filters={DICTIONARIES_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Dictionary>
              columns={columns}
              data={dictionaries?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
