import { styled } from "baseui";
import { StyledSpinnerNext } from "baseui/spinner";
import { Theme } from "baseui/theme";
import { LabelXSmall } from "baseui/typography";
import { Image } from "containers/Images/images";
import React, { ReactElement } from "react";

export enum ImagePreviewSize {
  Compact = "compact",
  Large = "large",
}

const ImagePreviewRoot = styled<
  { isSelected: boolean; size: ImagePreviewSize },
  "div",
  Theme
>("div", ({ $theme, isSelected, size }) => ({
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: isSelected ? $theme.colors.accent : $theme.colors.inputBorder,
  borderRadius: $theme.borders.radius300,
  height: size === ImagePreviewSize.Compact ? "120px" : "300px",
  cursor: "pointer",
  backgroundColor: $theme.colors.backgroundPrimary,
  display: "flex",
  flexDirection: "column",
}));

const ImagePreviewLabel = styled("div", ({ $theme }) => ({
  background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
  borderTopLeftRadius: $theme.borders.radius200,
  borderTopRightRadius: $theme.borders.radius200,
  paddingTop: $theme.sizing.scale200,
  paddingBottom: $theme.sizing.scale200,
  paddingLeft: $theme.sizing.scale200,
  paddingRight: $theme.sizing.scale200,
}));

const ImagePreviewBody = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  flexGrow: 1,
}));

type ImagePreviewProps = {
  image: Partial<Image>;
  isSelected?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  size?: ImagePreviewSize;
};

export default function ImagePreview({
  image,
  isSelected = false,
  isLoading = false,
  size = ImagePreviewSize.Compact,
  onClick,
}: ImagePreviewProps): ReactElement {
  return (
    <ImagePreviewRoot
      isSelected={isSelected}
      size={size}
      {...(image.url && {
        $style: {
          backgroundImage: `url(${image.url})`,
          backgroundPosition: "center center",
          backgroundSize: "auto 90%",
          backgroundRepeat: "no-repeat",
        },
      })}
      onClick={onClick}
    >
      {image.originalName && (
        <ImagePreviewLabel>
          <LabelXSmall
            $style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            color="white"
          >
            {image.originalName}
          </LabelXSmall>
        </ImagePreviewLabel>
      )}
      <ImagePreviewBody>
        {isLoading && (
          <>
            <StyledSpinnerNext />
            <LabelXSmall>Wgrywanie...</LabelXSmall>
          </>
        )}
      </ImagePreviewBody>
    </ImagePreviewRoot>
  );
}
