import {
  FormControl as BaseFormControl,
  FormControlProps,
} from "baseui/form-control";
import React, { ReactElement } from "react";

type Props = {
  required?: boolean;
  caption?: string | ReactElement;
} & FormControlProps;

const FormControl = ({
  required,
  caption,

  ...rest
}: Props): React.ReactElement => {
  return (
    <BaseFormControl
      {...rest}
      {...(required && { label: rest.label + " *" })}
      {...(caption && {
        caption,
      })}
    />
  );
};

export default FormControl;
