import { ApolloError, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "components/button";
import { FormControl } from "components/form-control";
import { ControlledInput } from "components/input";
import { Modal } from "components/modal";
import { CHANGE_PASSWORD } from "containers/MyAccount/my-account.gql";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";

import { INPUT_VALIDATION_ERROR } from "../../constants";

type ChangePasswordFormInputs = {
  currentPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
};

enum ChangePasswordField {
  CurrentPassword = "currentPassword",
  NewPassword = "newPassword",
  RepeatedNewPassword = "repeatedNewPassword",
}

type ChangePasswordModalProps = {
  close: () => void;
  isOpen: boolean;
};

export default function ChangePasswordModal({
  close,
  isOpen,
}: ChangePasswordModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ChangePasswordFormInputs>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      repeatedNewPassword: "",
    },
  });
  const watchFields = watch();

  const [changePassword, { error, loading }] = useMutation(CHANGE_PASSWORD);

  const onSubmit = async (values: ChangePasswordFormInputs) => {
    setIsLoading(true);

    try {
      await changePassword({
        variables: {
          changePasswordInput: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          },
        },
      });

      enqueueSnackbar({
        message: "Hasło zostało pomyślnie zmienione",
        variant: "success",
      });

      close();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close} autoFocus={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Zmiana hasła</ModalHeader>
        <ModalBody>
          <FormControl
            label="Aktualne hasło"
            required
            error={
              (errors.currentPassword && errors.currentPassword.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  INPUT_VALIDATION_ERROR &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) =>
                      vE?.property === ChangePasswordField.CurrentPassword
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledInput
              control={control}
              name={ChangePasswordField.CurrentPassword}
              type="password"
              autoComplete="current-password"
              rules={{
                required: FORM_VALIDATION_MESSAGES.required,
              }}
            />
          </FormControl>
          <FormControl
            label="Nowe hasło"
            required
            error={
              (errors.newPassword && errors.newPassword.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  INPUT_VALIDATION_ERROR &&
                !!error.graphQLErrors[0]?.extensions?.validationErrors?.find(
                  (vE: InputValidationError) =>
                    vE?.property === ChangePasswordField.NewPassword
                )?.errors.length)
            }
            disabled={isLoading}
          >
            <ControlledInput
              control={control}
              type="password"
              name={ChangePasswordField.NewPassword}
              autoComplete="new-password"
              rules={{
                required: FORM_VALIDATION_MESSAGES.required,
                validate: (value) =>
                  value === watchFields?.repeatedNewPassword ||
                  FORM_VALIDATION_MESSAGES.differentPasswords,
              }}
            />
          </FormControl>
          <FormControl
            label="Powtórz nowe hasło"
            required
            error={
              (errors.newPassword && errors.newPassword.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  INPUT_VALIDATION_ERROR &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) =>
                      vE?.property === ChangePasswordField.NewPassword
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledInput
              control={control}
              type="password"
              name={ChangePasswordField.RepeatedNewPassword}
              autoComplete="new-password"
              rules={{
                required: FORM_VALIDATION_MESSAGES.required,
                validate: (value) =>
                  value === watchFields?.newPassword ||
                  FORM_VALIDATION_MESSAGES.differentPasswords,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              close();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>
          <Button disabled={loading} isLoading={loading} type="submit">
            Potwierdź
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
