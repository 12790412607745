import { BasicFilter, FilterType } from "./../../filters.d";
import { RolesField } from "./roles.form";

export const ROLES_FILTERS: BasicFilter[] = [
  {
    id: RolesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: RolesField.Description,
    label: "Opis",
    type: FilterType.Text,
  },
  {
    id: RolesField.IsSystem,
    label: "Systemowa",
    type: FilterType.Boolean,
  },
];
