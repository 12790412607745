/* eslint-disable react/prop-types */
import { useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelSmall, ParagraphXSmall } from "baseui/typography";
import { Checkbox } from "components/checkbox";
import { Table } from "components/table";
import { Permission, PermissionGroup } from "containers/Roles/roles";
import {
  PERMISSION_GROUPS,
  PERMISSIONS_INDEX,
} from "containers/Roles/roles.gql";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Cell as TableCell, Row } from "react-table";
import { ChevronDown, ChevronDownLeft, ChevronRight } from "tabler-icons-react";

type PermissionsTableProps = {
  isReadOnly?: boolean;
  selectedIds?: number[];
  onChange?: (selectedIds: number[]) => void;
  error?: boolean;
};

export default function PermissionsTable({
  isReadOnly = false,
  selectedIds,
  onChange,
  error,
}: PermissionsTableProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<number[]>([]);

  const { data, loading } = useQuery(PERMISSION_GROUPS);
  const { data: permissionsData } = useQuery(PERMISSIONS_INDEX);
  const permissionGroups: PermissionGroup[] = data?.permissionGroups;
  const permissions: Permission[] = permissionsData?.permissions;

  useEffect(() => {
    if (permissionsData && selectedIds !== undefined)
      setSelectedGroupsIds(
        permissionsData?.permissions
          .filter(({ id }: Permission) => selectedIds.includes(id))
          .map(({ permissionGroupId }: Permission) => permissionGroupId)
      );
  }, [selectedIds, permissionsData]);

  const columns = useMemo(
    () => [
      {
        Header: "Uprawnienie",
        Cell: ({ row }: { row: Row<PermissionGroup> }) => (
          <>
            {row.canExpand && (
              <span
                {...row.getToggleRowExpandedProps()}
                className={css({ display: "flex", alignItems: "center" })}
              >
                {row.isExpanded ? (
                  <ChevronDown
                    size={16}
                    className={css({
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline",
                    })}
                  />
                ) : (
                  <ChevronRight
                    size={16}
                    className={css({
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline",
                    })}
                  />
                )}
                <div>
                  <LabelSmall>{row.original.name}</LabelSmall>
                  <ParagraphXSmall
                    marginTop="4px"
                    marginBottom={0}
                    $style={{ fontWeight: 500 }}
                  >
                    {row.original.description}
                  </ParagraphXSmall>
                </div>
              </span>
            )}
          </>
        ),
      },
      {
        id: "check",
        Cell: ({ row }: { row: Row<PermissionGroup> }) => (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {selectedIds === undefined ? (
              <Block
                marginLeft="2px"
                marginTop="2px"
                marginRight="10px"
                marginBottom="2px"
              >
                <Skeleton width="20px" height="20px" animation />
              </Block>
            ) : (
              <Checkbox
                checked={
                  selectedGroupsIds?.filter(
                    (groupId: number) => groupId === row.original.id
                  ).length === row.original.permissions.length
                }
                disabled={isReadOnly}
                isIndeterminate={
                  selectedGroupsIds?.filter(
                    (groupId: number) => groupId === row.original.id
                  ).length < row.original.permissions.length &&
                  selectedGroupsIds?.filter(
                    (groupId: number) => groupId === row.original.id
                  ).length > 0
                }
                {...(onChange && {
                  onChange: (event) => {
                    if (event.currentTarget.checked) {
                      onChange([
                        ...selectedIds,
                        ...permissions
                          .filter(
                            ({ permissionGroupId }: Permission) =>
                              permissionGroupId === row.original.id
                          )
                          .map(({ id }: Permission) => id),
                      ]);
                    } else {
                      onChange([
                        ...selectedIds.filter(
                          (selectedId: number) =>
                            !permissions
                              .filter(
                                ({ permissionGroupId }: Permission) =>
                                  permissionGroupId === row.original.id
                              )
                              .map(({ id }: Permission) => id)
                              .includes(selectedId)
                        ),
                      ]);
                    }
                  },
                })}
              />
            )}
          </div>
        ),
      },
    ],
    [selectedGroupsIds]
  );

  return loading ? (
    <Skeleton rows={0} height="270px" width="100%" animation />
  ) : (
    <Table
      compact
      columns={columns}
      data={permissionGroups}
      expandedNumber={0}
      $rows={(rows, prepareRow) => {
        return rows
          .map((row: any) => {
            row.canExpand = true;
            prepareRow(row);
            return row.isExpanded
              ? [
                  row.cells.map((cell: TableCell) => cell.render("Cell")),
                  ...row.original.permissions.map((permission: Permission) => [
                    <div
                      key={`permission-label ${permission.id}`}
                      className={css({
                        marginLeft: "26px",
                        display: "flex",
                        alignItems: "center",
                      })}
                    >
                      <ChevronDownLeft
                        color="#999999"
                        size={18}
                        className={css({ marginRight: "6px" })}
                      />
                      <div>
                        <LabelSmall>{permission.name}</LabelSmall>
                        <ParagraphXSmall
                          marginTop="4px"
                          marginBottom={0}
                          $style={{ fontWeight: 500 }}
                        >
                          {permission.description}
                        </ParagraphXSmall>
                      </div>
                    </div>,
                    <div
                      key={`permission ${permission.id}`}
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      })}
                    >
                      {selectedIds === undefined ? (
                        <Block
                          marginLeft="2px"
                          marginTop="2px"
                          marginRight="10px"
                          marginBottom="2px"
                        >
                          <Skeleton width="20px" height="20px" animation />
                        </Block>
                      ) : (
                        <Checkbox
                          checked={selectedIds.includes(permission.id)}
                          disabled={isReadOnly}
                          {...(onChange && {
                            onChange: (event) => {
                              if (event.currentTarget.checked) {
                                onChange([...selectedIds, permission.id]);
                              } else {
                                onChange([
                                  ...selectedIds.filter(
                                    (selctedId: number) =>
                                      selctedId !== permission.id
                                  ),
                                ]);
                              }
                            },
                          })}
                        />
                      )}
                    </div>,
                  ]),
                ]
              : [row.cells.map((cell: TableCell) => cell.render("Cell"))];
          })
          .flat();
      }}
      {...(error && {
        $tableStyle: {
          backgroundColor: theme.colors.inputFillError,
          borderColor: theme.colors.inputBorderError,
          borderWidth: "2px",
        },
      })}
    />
  );
}

export function ControlledPermissionsTable({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & PermissionsTableProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <PermissionsTable onChange={onChange} selectedIds={value} {...rest} />
      )}
    />
  );
}
