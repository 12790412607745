import { ApolloError, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "components/button";
import { FormControl } from "components/form-control";
import { ControlledInput } from "components/input";
import { Modal } from "components/modal";
import { FORGOT_PASSWORD } from "containers/Auth/auth.gql";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";
import { FORM_VALIDATION_PATTERNS } from "utils/form/validation-patterns";

import { INPUT_VALIDATION_ERROR } from "../../constants";

type ForgotPasswordFormInputs = {
  email: string;
};

enum ForgotPasswordField {
  Email = "email",
}

type ForgotPasswordModalProps = {
  close: () => void;
  isOpen: boolean;
};

export default function ForgotPasswordModal({
  close,
  isOpen,
}: ForgotPasswordModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordFormInputs>({
    defaultValues: {
      email: "",
    },
  });

  const [forgotPassword, { error, loading }] = useMutation(FORGOT_PASSWORD);

  const onSubmit = async (values: ForgotPasswordFormInputs) => {
    setIsLoading(true);

    try {
      await forgotPassword({
        variables: {
          forgotPasswordInput: values,
        },
      });

      enqueueSnackbar({
        message: "Link resetujący hasło został wysłany na podany adres e-mail",
        variant: "success",
      });

      close();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close} autoFocus={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Resetowanie hasła</ModalHeader>
        <ModalBody>
          <FormControl
            label="Adres e-mail"
            required
            error={
              (errors.email && errors.email.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  INPUT_VALIDATION_ERROR &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) =>
                      vE?.property === ForgotPasswordField.Email
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledInput
              control={control}
              name={ForgotPasswordField.Email}
              type="email"
              rules={{
                required: FORM_VALIDATION_MESSAGES.required,
                pattern: {
                  value: FORM_VALIDATION_PATTERNS.email,
                  message: FORM_VALIDATION_MESSAGES.incorrectEmail,
                },
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={close}
            disabled={loading}
          >
            Anuluj
          </Button>
          <Button disabled={loading} isLoading={loading} type="submit">
            Wyślij
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
