import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ButtonProps } from "baseui/button";
import { Pagination as BasePagination } from "baseui/pagination";
import { SelectProps } from "baseui/select";
import { Skeleton } from "baseui/skeleton";
import { Button } from "components/button";
import { Select } from "components/select";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import React, { useMemo } from "react";
import { ChevronLeft, ChevronRight } from "tabler-icons-react";

export default function Pagination(): React.ReactElement {
  const [css] = useStyletron();
  const { isFetching } = useLoading();

  const { pageSize, currentPage, totalCount, changePage } = usePaging();

  const memoizedNumPages = useMemo(() => Math.ceil(totalCount / pageSize), [
    pageSize,
    totalCount,
  ]);

  return (
    <BasePagination
      overrides={{
        Root: {
          style: {},
        },
        Select: {
          component: ({
            options,
            onChange,
            clearable,
            value,
            valueKey,
            labelKey,
          }: SelectProps) => (
            <Select
              size="mini"
              searchable={false}
              disabled={memoizedNumPages <= 1}
              {...{
                options,
                onChange,
                clearable,
                value,
                valueKey,
                labelKey,
              }}
            />
          ),
        },
        PrevButton: {
          component: ({ onClick, disabled }: ButtonProps) => (
            <Button
              size="mini"
              kind="secondary"
              startEnhancer={<ChevronLeft size={14} />}
              {...{
                onClick,
                disabled,
              }}
            />
          ),
        },
        NextButton: {
          component: ({ onClick, disabled }: ButtonProps) => (
            <Button
              size="mini"
              kind="secondary"
              startEnhancer={<ChevronRight size={14} />}
              {...{
                onClick,
                disabled,
              }}
            />
          ),
        },
        MaxLabel: {
          props: {
            className: css({ fontSize: "12px" }),
          },
          ...(isFetching && {
            component: () => (
              <Block marginRight="scale600" marginLeft="scale300">
                <Skeleton width="24px" height="14px" animation />
              </Block>
            ),
          }),
        },
      }}
      labels={{
        preposition: "z",
      }}
      size="compact"
      numPages={memoizedNumPages}
      currentPage={currentPage}
      onPageChange={({ nextPage }) => changePage(nextPage)}
    />
  );
}
