import { useAuth } from "contexts/auth-context";
import React from "react";
import { Redirect, Route as RouterRoute, RouteProps } from "react-router-dom";

type Props = {
  component: (() => React.ReactElement) | React.Component;
  $private?: boolean;
  $shared?: boolean;
} & RouteProps;

const Route = ({
  component: Component,
  $private,
  $shared,
  ...props
}: Props): React.ReactElement => {
  const { isAuthenticated } = useAuth();

  return (
    <RouterRoute
      {...props}
      render={(props) =>
        $shared ? (
          <Component {...props} />
        ) : $private ? (
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        ) : isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default Route;
