import { useStyletron } from "baseui";
import { PageSizeControl } from "components/pagination";
import React from "react";

import ScrollUpButton from "../scroll-up-button/scroll-up-button";
import Pagination from "./pagination";

export default function PagingControls(): React.ReactElement {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      })}
      data-ui="table-foot"
    >
      <PageSizeControl />

      <ScrollUpButton />

      <Pagination />
    </div>
  );
}
