import { Button } from "components/button";
import React, { useEffect, useState } from "react";
import { ArrowUp } from "tabler-icons-react";

export default function ScrollUpButton(): React.ReactElement {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const root = document?.querySelector("main > section");

    function checkPosition() {
      if (root && root.scrollTop > 0) setActive(true);
      else setActive(false);
    }

    if (root) {
      root.addEventListener("scroll", checkPosition);

      return () => {
        root.removeEventListener("scroll", checkPosition);
      };
    }
  }, []);

  return (
    <Button
      size="mini"
      kind="secondary"
      startEnhancer={<ArrowUp size={14} />}
      disabled={!active}
      onClick={() =>
        document?.querySelector("main > section")?.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      }
    >
      Wróć na górę
    </Button>
  );
}
